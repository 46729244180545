<template>
  <div class="signUp-page container-fluid">
    <div class="container">
      <div class="account-box">
        <span class="create">Create</span>
        <span class="account">Smart Forex Account</span>
      </div>
      <div class="account-form">
        <form class="form" @submit.prevent="submit">
          <div class="form-group col-md-12 col-sm-12 email">
            <input
              v-model="form.email"
              type="email"
              placeholder="Email*"
              required
            />
          </div>
          <div class="form-group col-md-12 col-sm-12 password">
            <input
              v-model="form.password"
              type="password"
              name="password"
              placeholder="Password*"
              required
            />
          </div>
          <div class="form-group col-md-12 col-sm-12 password-repeat">
            <input
              v-model="form.repeatPassword"
              type="password"
              name="password"
              placeholder="Password Repeat*"
              required
            />
          </div>
          <div class="person">
            <div class="form-group col-md-6 col-sm-6 name">
              <input
                v-model="form.name"
                type="text"
                placeholder="Name*"
                required
              />
            </div>
            <div class="form-group col-md-6 col-sm-6 surname">
              <input
                v-model="form.surname"
                type="text"
                placeholder="Surname*"
                required
              />
            </div>
          </div>
          <div class="place">
            <div class="form-group col-md-6 col-sm-6 dob">
              <input v-model="form.birthday" type="text" placeholder="DOB" />
            </div>
            <div class="form-group col-md-6 col-sm-6 country">
              <input
                v-model="form.countryCode"
                type="text"
                placeholder="Country"
              />
            </div>
          </div>
          <div class="form-group col-md-12 col-sm-12 phone">
            <input v-model="form.phone" type="phone" placeholder="Phone" />
          </div>

          <div class="checkbox-container">
            <div class="subscribe-checkbox">
              <CheckBox v-model="form.emailSubscribe" />
              <!-- <input type="checkbox" id="subscribe" /> -->
              <label for="subscribe">
                <span></span>
                Subscribe to email updates
              </label>
            </div>
            <div class="service-checkbox">
              <CheckBox v-model="form.acceptTerms" />
              <!-- <input type="checkbox" class="service" /> -->
              <span>I have read and agree to Binance’s Terms of Service</span>
            </div>
          </div>

          <div class="form-group col-md-12 col-sm-12 button-create">
            <button type="submit">Create account</button>
          </div>

          <div class="already-registered">
            <span class="already">Already registered ?</span>
            <router-link class="login_url" :to="{ name: 'sign-in' }"
              >Log In</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import CheckBox from './CheckBox.vue'

export default {
  components: { CheckBox },

  data: () => ({
    form: {
      email: '',
      password: '',
      repeatPassword: '',
      name: '',
      surname: '',
      referral: '',
      phone: '',
      countryCode: '',
      birthday: '',
      acceptTerms: true,
      emailSubscribe: true,
    },
  }),

  methods: {
    submit() {
      let form = {}

      // Remove empty values
      for (const key in this.form)
        if (this.form[key]) form[key] = this.form[key]

      this.signUp(form)
        .then(() => {
          this.$router.push({ name: 'account' })
        })
        .catch(err => {
          console.warn(err)
        })
    },

    ...mapActions('User', ['signUp']),
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
