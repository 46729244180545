<template>
  <div class="ui checkbox" :class="{ checked: value }" @click="toggle">
    <div class="check-point" v-if="value">
      <img src="~@assets/img/Vector.png" alt="vector" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['value'],

  methods: {
    toggle() {
      this.$emit('input', !this.value)
    },
  },
}
</script>

<style lang="scss">
.ui.checkbox {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-width: 20px;
  min-height: 20px;
  color: black;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: #1a3557;
  border: 1px solid #325179;
  box-sizing: border-box;
  border-radius: 3px;
  line-height: 20px;
  transition: 0.3s ease-out;
}

.checked {
  background-color: #f1f601 !important;
  transition: 0.3s ease-out;
}
</style>